import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import ReactGA from 'react-ga';
import Page from '@components/page';
import IntroModal from '@components/intro-modal';
import VideoPlayer from '@components/video-player';
import { Colors, Fonts } from '@styles';
import Assets from '@assets';
import AppLogo from '@assets/images/gene_pilot_logo.png';
import DATA from '@data';

const TopicsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 5%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  @media print {
    height: 100%;
    overflow: visible;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    padding: 0 2%;
    margin: 0;
    overflow: auto;
  }
`;

const TopicsListWrapper = styled.div`
  width: 394px;
  height: 100%;
  margin-right: 60px;
  display: flex;
  flex-direction: column;

  @media print {
    display: none;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 304px;
    margin-right: 25px;
  }
`;

const TopicsListTitle = styled.div`
  font-family: ${Fonts.bold};
  font-size: 1.2rem;
  color: ${Colors.primary};
  margin-bottom: 10px;
  height: 25px;
  font-weight: bold;
  height: auto;

  @media print {
    display: none;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 0.95rem;
  }
`;

const TopicsList = styled.div`
  width: 100%;
  height: 84%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: ${Colors.white};
  border-radius: 10px;
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    padding: 10px 20px;
  }

  @media print {
    display: none;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${Colors.scrollbarTrack};
    border-radius: 10px;
    margin: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${Colors.primary};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const TopicsListActionsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 75px;
`;

const TopicsListAction = styled.div`
  display: flex;
  flex: 1;
  background: ${({ type }) => (type === 'primary' ? Colors.primary : Colors.white)};
  color: ${({ type }) => (type === 'primary' ? Colors.white : Colors.primary)};
  margin-left: ${({ type }) => (type === 'primary' ? '10px' : null)};
  border-radius: 10px;
  height: 60px;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  box-shadow: ${props => (props.disabled ? 'none' : '0 0 10px 0 rgba(0, 0, 0, 0.1)')};
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  transition: all linear 0.2s;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};

  &:hover {
    transform: ${props => (props.disabled ? 'none' : 'translateY(-1px)')};
    opacity: 0.9;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 50px;
  }
`;

const TopicsContent = styled.div`
  flex: 1;
  height: 98%;
  background: ${Colors.white};
  box-shadow: none;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media print {
    border-radius: 0;
    overflow: visible;
  }
`;

const TopicsContentLogoWrapper = styled.div`
  display: none;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;

  @media print {
    display: flex;
    margin-top: 2%;
  }
`;

const TopicsContentLogo = styled.img`
  width: 110px;
  height: 110px;

  @media print {
    width: 95px;
    height: 95px;
  }
`;

const TopicsContentTitle = styled.div`
  height: 50px;
  width: 100%;
  background: ${Colors.orange};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.white};
  font-family: ${Fonts.bold};

  @media print {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: auto;
    padding: 7px 0;
  }
`;

const TopicsContentDivisionsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100% - 50px);
  width: 100%;
`;

const TopicsContentDivision = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 30px 50px;
  color: ${Colors.primary};
  font-size: 1.1rem;
  position: relative;

  strong {
    margin-bottom: 17px;
    font-family: ${Fonts.bold};
  }

  ol {
    padding-left: 20px;
    margin: 0;

    > li {
      margin-bottom: 30px;
      padding-left: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      ul {
        padding: 0;
        margin-left: 20px;

        li {
          margin: 12px 0;
        }
      }
    }
  }

  @media print {
    overflow: visible;
    padding: 20px;

    ol > li,
    ul > li {
      font-size: 0.9rem;
    }

    ol {
      padding-left: 10px;
      margin: 0;

      > li {
        margin-bottom: 15px;
        padding-left: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        ul {
          padding: 0;
          margin-left: 10px;

          li {
            margin: 6px 0;
          }
        }
      }
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${Colors.scrollbarTrack};
    border-radius: 10px;
    margin: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${Colors.primary};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ShareActionsContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  padding: 0 20px;

  @media print {
    display: none;
  }
`;

const ShareAction = styled.a`
  display: flex;
  background: ${Colors.white};
  width: 110px;
  height: 38px;
  justify-content: center;
  align-items: center;
  color: ${Colors.primary};
  border-radius: 7px;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  font-size: 1rem;
  margin: 0 5px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  transition: all linear 0.2s;
  text-decoration: none;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

  &:hover {
    transform: translateY(-1px);
    opacity: ${({ disabled }) => (disabled ? 0.6 : 0.9)};
  }
`;

const ShareActionIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const TopicItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all linear 0.2s;
  position: relative;
  background: #f2f5f8;
  border-radius: 10px;
  margin-bottom: ${({ lastChild }) => (lastChild ? null : '13px')};
  min-height: 38px;
  padding: 5px 15px;
  cursor: pointer;
  transition: all linear 0.2s;
  background: ${({ active }) => (active ? Colors.alphaDarkPurple : Colors.grey)};

  &:hover {
    background: ${Colors.alphaDarkPurple};
  }
`;

const TopicItemName = styled.div`
  font-weight: normal;
  color: ${Colors.primary};
  font-size: 0.9rem;
`;

const TopicItem = ({ data, number, active = false, lastChild = false, onClick }) => {
  const handleOnClick = () => {
    onClick(data.id);
  };

  return (
    <TopicItemContainer active={active} lastChild={lastChild} onClick={handleOnClick}>
      <TopicItemName>
        {number}. {data.title}
      </TopicItemName>
    </TopicItemContainer>
  );
};

function iOS() {
  if (typeof navigator !== 'undefined') {
    return (
      [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod' ].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
  return false;
}

const Navigate = () => {
  const pageTitle = 'Navigate';

  const [ showIntroModal, setShowIntroModal ] = useState(false);
  const [ completedSteps, setCompletedSteps ] = useState({});
  const [ activeTopicItems, setActiveTopicItems ] = useState([]);
  const [ submitedTopicItems, setSubmitedTopicItems ] = useState([]);
  const [ rightNavigationActive, setRightNavigationActive ] = useState(false);
  const [ renderFinalStep, setRenderFinalStep ] = useState(false);
  const [ finalStepRightNavigationActive, setFinalStepRightNavigationActive ] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('session-id')) {
      const navigateIntro = window.localStorage.getItem('navigate-intro');
      if (!navigateIntro || navigateIntro != 1) {
        setShowIntroModal(true);
      }

      let steps = window.localStorage.getItem('completed-steps');
      if (steps) {
        steps = JSON.parse(steps);
        setCompletedSteps(steps);

        if (steps.navigate === true) {
          setRightNavigationActive(true);
          setFinalStepRightNavigationActive(true);
          setShowIntroModal(false);
        }
      }
    } else {
      navigate('/intro');
    }
  }, []);

  const handleOnTopicItemClick = topicItemId => {
    if (activeTopicItems.includes(topicItemId)) {
      const topicItemIdIndex = activeTopicItems.findIndex(topic => topic === topicItemId);
      setActiveTopicItems([
        ...activeTopicItems.slice(0, topicItemIdIndex),
        ...activeTopicItems.slice(topicItemIdIndex + 1),
      ]);
    } else if (activeTopicItems.length < 5) {
      setActiveTopicItems([ ...activeTopicItems, topicItemId ]);
    }
  };

  const handleOnLeftNavigationPress = () => {
    if (completedSteps['soar']) {
      navigate('/soar');
    }
  };

  const handleOnRightNavigationPress = () => {
    let completedSteps = window.localStorage.getItem('completed-steps');
    if (completedSteps) {
      completedSteps = JSON.parse(completedSteps);
      completedSteps.navigate = true;
    }
    window.localStorage.setItem('completed-steps', JSON.stringify(completedSteps));

    setRenderFinalStep(true);
  };

  const handleOnFinalStepRightNavigationPress = () => {
    navigate('/landing');
  };

  const handleOnCancelBtnClick = () => {
    setActiveTopicItems([]);
    setSubmitedTopicItems([]);
    setRightNavigationActive(false);
  };

  const handleOnSubmitBtnClick = () => {
    if (activeTopicItems.length >= 3) {
      setRightNavigationActive(true);
      setSubmitedTopicItems(activeTopicItems);
    }
  };

  const handleOnPrintActionClick = e => {
    e.preventDefault();

    window.print();
  };

  const handleOnEmailActionClick = e => {
    e.preventDefault();

    const topics = submitedTopicItems.map(topicId => DATA.NAVIGATE.items.find(topic => topic.id === topicId));
    const subject = 'Gene Pilot';
    const body = `Summary Card\n\nConcerns:\n\n${topics
      .map(
        (topic, index) =>
          `${index + 1}. ${topic.title}\n\nQuestions for the Doctor:\n${topic.questions
            .map(q => `- ${q}`)
            .join('\n')}\n\n`
      )
      .join('\n')}`;
    window.open(`mailto:?subject=${subject}&body=${!iOS() ? encodeURIComponent(body) : body.replace(/\n/g, '%0A')}`);
  };

  const handleIntroModalOnClose = () => {
    setShowIntroModal(false);

    window.localStorage.setItem('navigate-intro', 1);
  };

  const handleOnVideoFinish = () => {
    if (!finalStepRightNavigationActive) {
      setFinalStepRightNavigationActive(true);
    }

    ReactGA.event({
      category: 'NAVIGATE',
      action: 'User viewed "Navigate" video',
    });
  };

  const renderActiveTopicItems = () => {
    const topics = submitedTopicItems.map(topicId => DATA.NAVIGATE.items.find(topic => topic.id === topicId));

    return (
      <React.Fragment>
        <TopicsContentLogoWrapper>
          <TopicsContentLogo src={AppLogo} />
        </TopicsContentLogoWrapper>
        <TopicsContentTitle>Summary Card</TopicsContentTitle>
        <TopicsContentDivisionsWrapper>
          <TopicsContentDivision>
            <strong>Concerns:</strong>
            <ol>
              {topics.map((topic, index) => (
                <li key={index}>
                  <div>{topic.title}</div>
                  <div style={{ marginTop: 15 }}>
                    <strong>Questions for the Doctor:</strong>
                  </div>
                  <ul>{topic.questions.map((question, index) => <li key={index}>{question}</li>)}</ul>
                </li>
              ))}
            </ol>
            <ShareActionsContainer>
              <ShareAction href="" onClick={handleOnPrintActionClick} disabled={submitedTopicItems.length === 0}>
                <ShareActionIcon src={Assets.images.PrintIcon} />
                Print
              </ShareAction>
              <ShareAction href="" onClick={handleOnEmailActionClick} disabled={submitedTopicItems.length === 0}>
                <ShareActionIcon src={Assets.images.EmailIcon} />
                Email
              </ShareAction>
            </ShareActionsContainer>
          </TopicsContentDivision>
        </TopicsContentDivisionsWrapper>
      </React.Fragment>
    );
  };

  if (renderFinalStep) {
    return (
      <Page
        title={pageTitle}
        icon={Assets.images.PageIconNavigate}
        audio={Assets.audios.PageAudioNavigate}
        withNavigation
        withFloatNavigation
        leftNavigation={true}
        leftNavigationActive={true}
        leftNavigationText="Soar"
        onLeftNavigationPress={handleOnLeftNavigationPress}
        rightNavigationActive={finalStepRightNavigationActive}
        rightNavigationText="Landing"
        onRightNavigationPress={handleOnFinalStepRightNavigationPress}
      >
        <VideoPlayer
          file={DATA.NAVIGATE.file}
          transcript={DATA.NAVIGATE.file_transcript}
          onFinish={handleOnVideoFinish}
          thumbnail={DATA.NAVIGATE.file_thumbnail}
          autoplay={!iOS()}
        />
      </Page>
    );
  }

  return (
    <React.Fragment>
      <Page
        layout={iOS() ? '12% 1% 66% 11% 2%' : null}
        title={pageTitle}
        icon={Assets.images.PageIconNavigate}
        audio={Assets.audios.PageAudioNavigate}
        withNavigation
        fullContent
        hasOverflow={false}
        leftNavigationActive={true}
        leftNavigationText="Soar"
        onLeftNavigationPress={handleOnLeftNavigationPress}
        rightNavigationActive={rightNavigationActive}
        rightNavigationText="Next"
        onRightNavigationPress={handleOnRightNavigationPress}
      >
        <TopicsContainer>
          <TopicsListWrapper>
            <TopicsListTitle>Pick 3-5 topics that are concerning to you:</TopicsListTitle>
            <TopicsList>
              {DATA.NAVIGATE.items.map((topic, index) => (
                <TopicItem
                  key={topic.id}
                  number={index + 1}
                  lastChild={index === DATA.NAVIGATE.items.length - 1}
                  data={topic}
                  active={activeTopicItems.includes(topic.id)}
                  onClick={handleOnTopicItemClick}
                />
              ))}
            </TopicsList>
            <TopicsListActionsWrapper>
              <TopicsListAction onClick={handleOnCancelBtnClick}>Cancel</TopicsListAction>
              <TopicsListAction onClick={handleOnSubmitBtnClick} disabled={activeTopicItems.length < 3} type="primary">
                Get Questions
              </TopicsListAction>
            </TopicsListActionsWrapper>
          </TopicsListWrapper>
          <TopicsContent>{renderActiveTopicItems()}</TopicsContent>
        </TopicsContainer>
      </Page>
      {showIntroModal ? (
        <IntroModal
          icon={Assets.images.PageIntroIconNavigate}
          audio={Assets.audios.PageAudioNavigate}
          onClose={handleIntroModalOnClose}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Navigate;
